/* eslint-disable react/prop-types */
import { Spin } from 'antd'
import '../../assets/styles/spin.scss'
import s from './loader.module.scss'
import halfLogo from 'src/assets/images/half-logo.svg'

const Loader = ({ text }) => {
    return (
        <div className="SpinDiv">
            <Spin tip={text ? text : 'Loading...'} size="large"></Spin>
        </div>
    )
}

export const InlineLoader = ({
    color = 'white',
    wrapperWidth,
    dotSize,
    visibility = 'visible',
}) => {
    const classMap = {
        white: 'BtnLoading',
        primary: 'BtnLoadingPrimary',
    }

    return (
        <div
            className="LoadingWrapper"
            style={{
                width: wrapperWidth,
                visibility: visibility,
            }}
        >
            <div
                className={classMap[color]}
                style={{ width: dotSize, height: dotSize }}
            ></div>
        </div>
    )
}

export const InlineLoaderDark = ({ sseUse }) => {
    return (
        <div className="LoadingWrapper">
            <div
                className={`BtnLoadingDark ${sseUse ? 'sseSmallerIcon' : ''}`}
            ></div>
        </div>
    )
}

export const DotsLineLoader = ({ dotsSize = '3.24px' }) => {
    const dotsStyle = {
        width: dotsSize,
        height: dotsSize,
    }
    return (
        <div className={s.dotsLineLoader}>
            <div
                className={`${s.dotsLineDot} ${s.dotsLineDot1}`}
                style={dotsStyle}
            ></div>
            <div
                className={`${s.dotsLineDot} ${s.dotsLineDot2}`}
                style={dotsStyle}
            ></div>
            <div
                className={`${s.dotsLineDot} ${s.dotsLineDot3}`}
                style={dotsStyle}
            ></div>
        </div>
    )
}

export const LogoLoader = () => {
    return (
        <div className={s.logoLoader}>
            <div className={s.topHalfWrapper}>
                <img src={halfLogo} alt="logo" className={s.topHalf} />
            </div>
            <div className={s.bottomHalfWrapper}>
                <img src={halfLogo} alt="logo" className={s.bottomHalf} />
            </div>
        </div>
    )
}

export default Loader
